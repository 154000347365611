import styled, { css } from 'styled-components'

export const Input = styled.button`
  background: white !important;
  width: 100%;
  height: 40px;
  padding: 0.2rem 0 !important;
  text-align: left;
  border: 1px solid ${({ error, success }) => (success ? '#008200' : error ? '#d62d14' : '#6e6e6e')} !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${({ selectIsFocused }) => (selectIsFocused ? '100' : 'inherit')};
  border-radius: 0.2rem;
  border-bottom-left-radius: ${({ selectIsFocused }) => (selectIsFocused ? '0px' : '0.2rem')};
  border-bottom-right-radius: ${({ selectIsFocused }) => (selectIsFocused ? '0px' : '0.2rem')};
  box-shadow: ${({ selectIsFocused }) =>
    selectIsFocused
      ? '0 1rem 1rem 0 rgb(0 0 0 / 10%), 0 2rem 2rem 0 rgb(0 0 0 / 10%), 0 3rem 3rem 0 rgb(0 0 0 / 15%);'
      : 'none'};
  font-family: 'FordAntenna';
  transition: box-shadow 0.3s ease-in-out;
  cursor: ${({ hasOptions }) => (hasOptions ? 'pointer' : 'not-allowed !important')};
`
export const SelectWrapper = styled.div`
  position: relative;
`
export const OptionsWrapper = styled.div`
  position: absolute;
  top: 48px;
  background-color: white;
  width: 100%;
  z-index: 99;
  border: solid 1px #6e6e6e;
  border-top: none;
  max-height: calc(5 * 5rem);
  padding: 0;
  outline: none;
  box-shadow: 0 1rem 1rem 0 rgb(0 0 0 / 10%), 0 2rem 2rem 0 rgb(0 0 0 / 10%), 0 3rem 3rem 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.3s ease-in-out;
  display: ${({ display }) => (display ? 'block' : 'none')};
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  overflow-y: auto;
`

export const Option = styled.div`
  cursor: pointer;
  padding: 10px;
  color: #00095b;
  font-size: 0.9rem;
  line-height: 3rem;
  padding: 0 1rem;
  &:hover {
    background-color: #6e6e6e;
    color: white;
  }
`

export const InputSpan = styled.span`
  color: #00095b;
  padding: 10px;
  font-size: 0.9rem;
  width: auto;
  padding-right: 20px;
  line-height: 1.6rem;
  letter-spacing: 1px;
  font-weight: 400 !important;
`

export const Label = styled.label`
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: inline-block;
  font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
  color: #4d4d4d;
`

export const SelectContainer = styled.div`
  min-height: 80px;
  margin-bottom: 5px;
`

export const ErrorMessage = styled.span`
  position: absolute;
  top: 37px;
  padding-top: 0.7rem;
  color: #d62d14;
  font-size: 0.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
`

export const InputInnerWrapper = styled.div`
  padding-left: 0.4rem !important;
`
