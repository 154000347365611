export const ROOT_URL = 'http://localhost:3000'

export const STRAPI_URL = 'https://www.ford360guide.com'

export const experiences = {
  guia_360_fordranger_br: {
    ranger_360_xlt: `https://interactive.guia360fordranger.com.br/still/?car=xlt&inst=true`,
    ranger_360_limited: `https://interactive.guia360fordranger.com.br/360/?car=limited&inst=true`,
    ranger_still_xls: `https://interactive.guia360fordranger.com.br/still/?car=xls`,
    ranger_still_storm: `https://interactive.guia360fordranger.com.br/still/?car=storm`,
    ranger_still_black: `https://interactive.guia360fordranger.com.br/still/?car=black`
  },
  guia_360_br: {
    ranger_360_xlt: `https://interactive.guia360ford.com.br/still/ranger/?car=xlt&inst=true`,
    ranger_360_limited: `https://interactive.guia360ford.com.br/360/ranger/?car=limited&inst=true`,
    ranger_still_xls: `https://interactive.guia360ford.com.br/still/ranger/?car=xls`,
    ranger_still_storm: `https://interactive.guia360ford.com.br/still/ranger/?car=storm`,
    ranger_still_black: `https://interactive.guia360ford.com.br/still/ranger/?car=black`,
    bronco_sport: `https://interactive.guia360ford.com.br/bronco/?r=t`,
    territory_still_titanium: `https://interactive.guia360ford.com.br/still/territory/?car=titanium`,
    territory_still_sel: `https://interactive.guia360ford.com.br/still/territory/?car=sel`,
    territory_360_titanium: `https://interactive.guia360ford.com.br/360/territory/?inst=true&car=titanium`,
    territory_360_sel: `https://interactive.guia360ford.com.br/360/territory/?inst=true&car=sel`,
    maverick_360: `https://interactive.guia360ford.com.br/360/maverick/?car=maverick&lang=br`,
    ranger_360_raptor: `https://interactive.guia360ford.com.br/360/maverick/?car=ranger&lang=br`,

    // NEW
    ranger_360_limited_v6_2023: `https://interactive.guia360ford.com.br/360/v2/?car=ranger&lang=br&model=limited_v6`,
    ranger_360_xl_4x4_mt_2023: `https://interactive.guia360ford.com.br/360/v2/?car=ranger&model=xl_4x4_mt_2023&lang=br`,
    ranger_limitedv6_2023: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=limitedv6_2023`,
    ranger_xltv6_2023: `https://interactive.guia360ford.com.br/still/v2/?car=ranger&model=xltv6_2023&lang=br`,
    ranger_xls4x4_at_2023: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=xls4x4_2023`,
    ranger_xls4x2_at_2023: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=xls4x2_at_2023`,
    // END

    ranger_xls4x4_2022: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=xls4x4`,
    ranger_storm_2022: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=storm`,
    ranger_storm_2023: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=storm_2023`,
    ranger_fx4_2023: `https://interactive.guia360ford.com.br/still/v2/?lang=br&car=ranger&model=fx4_2023`,

    mustang_mach_e_360: `https://interactive.guia360ford.com.ar/360/v2/?car=mustang&lang=br&model=mach_e`
  },
  guia_360_ar: {
    ranger_360_limited: `https://interactive.guia360ford.com.ar/360/ranger/?car=limited&inst=true`,
    ranger_still_xls4x4: `https://interactive.guia360ford.com.ar/still/ranger/?car=xls4x4`,
    ranger_still_xls4x2: `https://interactive.guia360ford.com.ar/still/ranger/?car=xls4x2`,
    ranger_still_xlt4x4: `https://interactive.guia360ford.com.ar/still/ranger/?car=xlt4x4`,
    ranger_still_xlt4x2: `https://interactive.guia360ford.com.ar/still/ranger/?car=xlt4x2`,
    ranger_still_raptor: `https://interactive.guia360ford.com.ar/still/ranger/?car=raptor`,
    bronco_sport_360: `https://interactive.guia360ford.com.ar/360/bronco/?r=t`,
    bronco_sport_still: `https://interactive.guia360ford.com.ar/still/bronco/?r=t`,
    territory_still_titanium: `https://interactive.guia360ford.com.ar/still/territory/?car=titanium`,
    territory_still_sel: `https://interactive.guia360ford.com.ar/still/territory/?car=sel`,
    territory_360_titanium: `https://interactive.guia360ford.com.ar/360/territory/?inst=true&car=titanium`,
    territory_360_sel: `https://interactive.guia360ford.com.ar/360/territory/?inst=true&car=sel`,

    maverick_360: `https://interactive.guia360ford.com.br/360/v2/?car=maverick&lang=ar`,

    // NEW
    ranger_360_raptor_2023: `https://interactive.guia360ford.com.ar/360/v2/?car=ranger&model=raptor_2023&lang=ar`,
    ranger_360_limited_v6_2023: `https://interactive.guia360ford.com.ar/360/v2/?car=ranger&model=limited_v6&lang=ar`,
    ranger_360_xl_4x4_mt_2023: `https://interactive.guia360ford.com.ar/360/v2/?car=ranger&model=xl_4x4_mt_2023&lang=ar`,
    ranger_360_xlt_4x4_at_2023: `https://interactive.guia360ford.com.ar/360/v2/?car=ranger&model=xlt_4x4_at_2023&lang=ar`,
    ranger_360_xls_v6_2023: `https://interactive.guia360ford.com.ar/360/v2/?car=ranger&model=xls_v6_2023&lang=ar`,
    ranger_limited_2023: `https://interactive.guia360ford.com.ar/still/v2/?lang=ar&car=ranger&model=limited_2023`,
    ranger_xlt4x2_at_2023: `https://interactive.guia360ford.com.ar/still/v2/?lang=ar&car=ranger&model=xlt4x2_2023`,
    ranger_xls4x2_mt_2023: `https://interactive.guia360ford.com.ar/still/v2/?lang=ar&car=ranger&model=xls4x2_mt_2023`,
    ranger_xl4x2_mt_2023: `https://interactive.guia360ford.com.ar/still/v2/?lang=ar&car=ranger&model=xl4x2_2023`,
    // END

    mustang_mach_e_360: `https://interactive.guia360ford.com.ar/360/v2/?car=mustang&lang=ar&model=mach_e`
  },
  guia_360_fordranger_ar: {
    ranger_360_limited: `https://interactive.guia360fordranger.com.ar/360/?car=limited&inst=true`,
    ranger_still_xls4x4: `https://interactive.guia360fordranger.com.ar/still/?car=xls4x4`,
    ranger_still_xls4x2: `https://interactive.guia360fordranger.com.ar/still/?car=xls4x2`,
    ranger_still_xlt4x4: `https://interactive.guia360fordranger.com.ar/still/?car=xlt4x4`,
    ranger_still_xlt4x2: `https://interactive.guia360fordranger.com.ar/still/?car=xlt4x2`,
    ranger_still_raptor: `https://interactive.guia360fordranger.com.ar/still/?car=raptor`
  },
  guia_360_fordbronco_ar: {
    bronco_sport_360: `https://interactive.guia360fordbroncosport.com.ar/360/?r=t`,
    bronco_sport_still: `https://interactive.guia360fordbroncosport.com.ar/still/?r=t`
  },
  guia_360_fordbronco_br: {
    bronco_sport: `https://interactive.guia360fordbroncosport.com.br/?r=t`
  }
}
