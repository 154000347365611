import React from 'react'

import { Link } from '@reach/router'
import { navigate } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Select from '../Select'
import { SelectsWrapper, SelectContainer, ButtonNext, ButtonNextDisable, ButtonsWrapper } from './styles'
import { useSelectModel } from './useSelectModel'

export const SelectModel = ({ homeSelects, car, models, hasYearDropDown, nextButtonText, envs }) => {
  const breakpoints = useBreakpoint()
  const {
    handleSelectYearOption,
    handleSelectVersionOption,
    handleSelectModelOption,
    selectedYear,
    selectedVersion,
    selectedModel,
    yearOptions,
    versionOptions,
    modelOptions,
    showModelSelect,
    showContinueButton,
    buildModelLink
  } = useSelectModel({ models, hasYearDropDown, envs, car })

  return (
    <SelectsWrapper isTablet={breakpoints.l} className="fds-sm:fds-flex__row-xs fds-md:fds-flex__row-md vehicle-type">
      {hasYearDropDown && (
        <SelectContainer>
          <Select
            placeholder={homeSelects.year_select.placeholder_text}
            value={selectedYear}
            options={yearOptions}
            label={homeSelects.year_select.label_text}
            onChange={option => handleSelectYearOption(option.value)}
            textError={homeSelects.year_select.error_text}
          />
        </SelectContainer>
      )}
      <SelectContainer>
        <Select
          placeholder={homeSelects.version_select.placeholder_text}
          options={versionOptions}
          value={selectedVersion}
          label={homeSelects.version_select.label_text}
          onChange={option => handleSelectVersionOption(option.value)}
          textError={homeSelects.version_select.error_text}
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          placeholder={homeSelects.model_select.placeholder_text}
          value={selectedModel}
          options={modelOptions}
          label={homeSelects.model_select.label_text}
          onChange={option => handleSelectModelOption(option.value)}
          textError={homeSelects.model_select.error_text}
        />
      </SelectContainer>
      <ButtonsWrapper>
        {showContinueButton ? (
          <ButtonNext
            onClick={() => {
              if (window.gtag && car) {
                window.gtag('event', 'go-to-experience', {
                  event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                  event_label: window.location.href
                })
              }

              window.location.href = buildModelLink()
            }}
            style={{ width: '100%' }}
          >
            <a style={{ width: '100%' }}>
              <span style={{ marginRight: '10px', fontSize: '18px !important', lineHeight: '24px !important' }}>
                {nextButtonText}
              </span>
              <span
                className="fds-icon fds-color__text--dk-white"
                style={{ fontSize: '9px !important', width: '189.15px !important' }}
              >
                <span className="fds-font--ford-icons__chevron-right" style={{ fontSize: '9px !important' }}></span>
              </span>
            </a>
          </ButtonNext>
        ) : (
          <div
            className="js-fds-primary-button fds-primary-button fds-color__bg--light fds-primary-button--disabled"
            style={{ width: '100%' }}
          >
            <ButtonNextDisable className="fds-primary-button__button" disabled style={{ width: '100% !important' }}>
              <span
                className="fds-primary-button__button-text"
                style={{ fontSize: '18px !important', width: '100% !important' }}
              >
                {nextButtonText}
                <span
                  className="fds-icon fds-color__text--white"
                  style={{ display: 'block', transform: 'rotate(180deg)', color: '#fff' }}
                >
                  <span className="fds-font--ford-icons__chevron-left"></span>
                </span>
              </span>
            </ButtonNextDisable>
          </div>
        )}
      </ButtonsWrapper>
    </SelectsWrapper>
  )
}
