import styled, { css } from 'styled-components'

export const SelectsWrapper = styled.div`
  max-width: 400px;
  padding: 0 23px;
  margin-bottom: 10px;
  user-select: none;

  ${({ isTablet }) =>
    isTablet &&
    css`
      max-width: 325px;
    `};
`

export const SelectContainer = styled.div`
  max-width: 397px;
  margin: 20px auto;

  label {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 16px;
    font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    color: #4d4d4d;
  }
`

export const ButtonNext = styled.div`
  /* margin: auto;
  margin-bottom: 25px;
  margin-top: 25px; */

  height: 40px;
  width: 100%;
  /* margin-top: 5px; */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 1023px) {
    width: 165px;
  }
  /* 
  .fds-primary-button__button-text {
    width: 100% !important;
    padding: 0 !important;
    width: auto !important;
    display: inline-flex;
    font-family: FordAntennaCondRegular !important;
    line-height: 24px !important;
  } */

  a {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    font-family: FordAntennaCondRegular;
    font-size: 1.125rem;
    /* line-height: 1.5rem; */
    line-height: 1.3rem;
    letter-spacing: 1px;
    text-transform: none;
    text-align: center;
    padding: 0.5rem 2rem 0.5rem;
    width: 100%;
    margin-bottom: 0;
    border-radius: 2rem;
    border: 2px solid transparent;
    cursor: pointer;
    color: #fff !important;
    background-color: #00095b;
    border-color: #00095b;
    font-size: 15px !important;

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    &:hover {
      background-color: #1700f4;
      border-color: #1700f4;
      color: #fff;
      transform: scale(1.07);
    }

    .fds-icon {
      font-size: 14px;
      font-weight: 800;
      font-family: FordAntennaCondRegular;
    }
  }
`

export const ButtonNextDisable = styled.button`
  height: 40px;
  width: 100%;
  border-radius: 20px;
  background-color: #6e6e6e;
  border: none;
  /* font-family: 'Ford Antenna'; */
  /* font-size: 18px;
  letter-spacing: 1px;
  line-height: 24px; */
  text-align: center;
  color: #ffffff;
  cursor: no-drop;
  place-content: center;
  font-family: FordAntennaCondRegular;
  margin-top: 10px;
  /* font-size: 18px !important; */

  .fds-primary-button__button-text {
    font-size: 15px !important;
    width: 100% !important;
    padding: 0 !important;
    width: auto !important;
    display: inline-flex;
    font-family: FordAntennaCondRegular !important;
    line-height: 24px !important;
    text-transform: none !important;
  }

  .fds-icon {
    margin-left: 0.5rem;
    place-self: center;
    font-size: 0.9rem;
  }

  .fds-font--ford-icons__chevron-left::before {
    color: #ffffff;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
