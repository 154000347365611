import React from 'react'
import { FooterWrapper, Container } from './styles'

import Logo from '../../assets/images/logo.svg'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const Footer = ({ privacyPolicyUrl, footer, header }) => {
  const breakpoints = useBreakpoint()

  const handlePrivacyPolicyClick = () => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: `privacy-policy`
      })
    }

    window.location.href = privacyPolicyUrl
  }

  const handleLogoClick = () => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: `institutional-website`
      })
    }

    window.location.href = footer?.logo_link || ''
  }

  return (
    <Container isTablet={breakpoints.l}>
      <FooterWrapper>
        <p>{footer?.copyright_text || ''}</p>
        <a href="#" onClick={() => handlePrivacyPolicyClick()}>
          {footer?.privacy_policy_text}
        </a>
        <a href="#" onClick={() => handleLogoClick()}>
          <img src={header?.logo?.url ? `https://www.ford360guide.com${header?.logo?.url}` : Logo} alt="" />
        </a>
      </FooterWrapper>
    </Container>
  )
}

export default Footer
