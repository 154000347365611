import styled, { css } from 'styled-components'

export const FooterWrapper = styled.div`
  /* display: flex;
    flex-direction: row; */
  height: 80px;
  justify-content: space-between;
  padding: 0;
  align-items: center;

  justify-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  img {
    width: 95px;
  }

  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 80px;
    padding: 0 24px;
    height: 18vh;
    padding: 10px 25px;
    padding: 10px 35px;

    p {
      width: 250px;
      display: block;
    }

    img {
      width: 86px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 10px 10px;
  }

  p {
    color: #4d4d4d;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 16px;
    font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    /* width: 27%; */
    /* width: 100%; */
    @media only screen and (max-width: 600px) {
      /* width: 83%; */
    }
  }
  a {
    font-size: 11px;
    line-height: 16px;
    color: #1700f4;
    /* font-weight: 400; */
    font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    width: 50%;
    text-decoration: underline;
    width: fit-content;
    letter-spacing: 1px;

    @media only screen and (max-width: 1023px) {
      margin: 20px 0px;
    }
  }

  @media only screen and (min-width: 1024px) {
    p {
      justify-self: start;
    }

    img {
      justify-self: right;
    }
  }

  @media only screen and (min-width: 1440px) {
    p {
      width: max-content;
    }
  }
`

export const Container = styled.div`
  /* padding: 10px 80px; */
  padding: 0px 100px;
  /* margin: -8px; */
  background-color: white;

  @media only screen and (max-width: 600px) {
    padding: 0px;
    min-height: 25vh;
  }

  @media only screen and (max-width: 1023px) {
    margin: 0;
    padding: 10px 15px;
    margin-top: 15px;

    @media (orientation: landscape) {
      padding: 40px 15px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    padding: 10px 80px;
  }

  @media only screen and (min-width: 1440px) {
    min-height: 11.5vh;
  }

  @media only screen and (max-width: 1439px) {
    margin: 0;
    /* padding: 10px 15px; */
  }
`
