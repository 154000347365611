import React from 'react'
import { Container, CharactericticsWrapper } from './styles'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { sortByPriorityCars } from '../../../../helpers'
import { CarCardShow } from './car-card-show'
import { CarSlider } from './car-slider'

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;

  .titleWrapper {
    /* max-width: 820px; */
    /* width: 85%; */

    @media only screen and (max-width: 600px) {
      max-width: 316.41px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media only screen and (max-width: 1023px) {
      max-width: 628px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  h1 {
    color: #00095b;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 60px;
    text-align: left;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
      font-size: 32px;
      line-height: 38px;
    }
  }
  p {
    color: #00095b;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-top: 30px;
    text-align: left;
    font-weight: 400;
    width: 80%;
    margin: 30px auto 0;

    @media only screen and (min-width: 601px) {
      width: 100%;
    }

    @media only screen and (max-width: 600px) {
      color: #00095b;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
      font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    }
  }
`

const WrapperChars = styled.div`
  width: 95%;
  margin: 0 0 0 auto;

  @media (max-width: 768px) {
    width: 95%;
    margin-top: 24px;
  }
`

const BreakLine = styled.hr``

const CategoryTitle = styled.h5`
  font-size: 20px !important;
  text-transform: uppercase;
  margin-top: 20px !important;
`

const CategoryWrapper = styled.div`
  margin: 20px 0;
`

const CarCards = ({
  cars,
  title,
  second_subtitle,
  pageContext,
  categoryTableName,
  disable_button_text,
  redirect_link_button_text
}) => {
  const breakpoints = useBreakpoint()

  let groupCars = {}
  const filteredCard = cars.filter(car => !!car[categoryTableName])

  filteredCard.map(car => {
    if (groupCars[car[categoryTableName].category_name]) {
      groupCars[car[categoryTableName].category_name] = [...groupCars[car[categoryTableName].category_name], car]
    } else {
      groupCars[car[categoryTableName].category_name] = [car]
    }
  })

  const categories = [
    ...new Set(
      filteredCard
        .map(car => car[categoryTableName])
        .sort((a, b) => {
          return sortByPriorityCars(a, b)
        })
        .map(category => category.category_name)
    )
  ]

  return (
    <Container>
      <Wrapper>
        <div className="fds-color__text--primary titleWrapper">
          <h1 style={{ textAlign: breakpoints.sm ? 'center' : 'left' }}>{title}</h1>
        </div>

        <div className="fds-color__text--primary" style={{ zIndex: 2 }}>
          {/* <p className="text--subtitle" style={{ margin: '8px auto 0', fontSize: '18px' }}>
            {subtitle}
          </p> */}
          <p
            className="text--subtitle"
            style={{
              margin: breakpoints.sm ? '8px auto 0' : '10px 0',
              fontSize: '16px',
              textAlign: breakpoints.sm ? 'center' : 'left'
            }}
          >
            {second_subtitle}
          </p>
        </div>
      </Wrapper>
      {breakpoints.sm ? (
        <WrapperChars>
          {categories.map((carCategory, indexCat) => {
            const sortedCars = groupCars[carCategory].sort((a, b) => {
              return sortByPriorityCars(a, b)
            })
            return (
              <>
                <CategoryTitle>{carCategory}</CategoryTitle>
                <div style={{ margin: '20px 0' }}>
                  <CarSlider
                    cars={sortedCars}
                    pageContext={pageContext}
                    disable_button_text={disable_button_text}
                    redirect_link_button_text={redirect_link_button_text}
                  />
                </div>
              </>
            )
          })}
        </WrapperChars>
      ) : (
        <div>
          {categories.map((carCategory, indexCat) => {
            const sortedCars = groupCars[carCategory].sort((a, b) => {
              return sortByPriorityCars(a, b)
            })

            return (
              <CategoryWrapper key={indexCat}>
                <BreakLine />
                <CategoryTitle>{carCategory}</CategoryTitle>
                <CharactericticsWrapper>
                  {sortedCars.map((car, index) => {
                    const carPicture = car?.car_picture?.url
                    return (
                      <CarCardShow
                        car={car}
                        carPicture={carPicture}
                        pageContext={pageContext}
                        key={index}
                        disable_button_text={disable_button_text}
                        redirect_link_button_text={redirect_link_button_text}
                      />
                    )
                  })}
                </CharactericticsWrapper>
              </CategoryWrapper>
            )
          })}
        </div>
      )}
    </Container>
  )
}

export default CarCards
