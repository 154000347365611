import React, { useState } from 'react'
import { ButtonPrimary, CardCharacNew, CardTitleNew, CharImage } from './styles'

import { SelectModel } from '../../../../components/SelectModel'
import styled from 'styled-components'
import { Link } from 'gatsby'

const ArrowSpan = styled.span`
  .fds-font--ford-icons__chevron-left::before {
    color: black;
  }
`

export const CarCardShow = ({ pageContext, car, carPicture, disable_button_text, redirect_link_button_text }) => {
  const [open, setIsOpen] = useState(false)

  const carVersionsIds = car[pageContext.envs.VERSIONS].map(version => version.id)
  const carModels = pageContext.models.filter(model => carVersionsIds.includes(model[pageContext.envs.VERSION].id))

  return (
    <CardCharacNew isOpen={open} style={{ height: open ? 'initial' : '225px', cursor: 'pointer' }}>
      <CharImage
        url={carPicture && `https://www.ford360guide.com${carPicture}`}
        onClick={() => car.is_active && setIsOpen(state => !state)}
      />
      <div onClick={() => car.is_active && setIsOpen(state => !state)}>
        <CardTitleNew>
          {car.is_active && (
            <>
              <span className="title">{car.car_name}</span>
              {!car.redirect_url && (
                <ArrowSpan className="fds-icon" style={{ display: 'block' }}>
                  <span className={`fds-font--ford-icons__chevron-${open ? 'down' : 'up'}`} />
                </ArrowSpan>
              )}
            </>
          )}

          {!car.is_active && (
            <>
              <span className="title">{car.car_name}</span>

              <ButtonPrimary disabled>
                <a style={{ minWidth: '80px', fontSize: '16px', padding: '0.3rem 1rem 0.3rem' }}>
                  <span>{disable_button_text}</span>
                </a>
              </ButtonPrimary>
            </>
          )}

          {car.is_active && car.redirect_url && (
            <ButtonPrimary>
              <Link to={car.redirect_url} style={{ minWidth: '80px', fontSize: '16px', padding: '0.3rem 1rem 0.3rem' }}>
                <span>{redirect_link_button_text}</span>
              </Link>
            </ButtonPrimary>
          )}
        </CardTitleNew>
      </div>
      {open && !car.redirect_url && (
        <SelectModel
          car={car}
          homeSelects={pageContext.website.home_selects}
          models={carModels}
          hasYearDropDown={car.has_model_year === false ? false : true}
          nextButtonText={pageContext.website.button_text}
          envs={pageContext.envs}
        />
      )}
    </CardCharacNew>
  )
}
