import React from 'react'

import { HeaderWrapper, Container } from './styles'
import Logo from '../../assets/images/logo.svg'

const HeaderHome = ({ header }) => {
  return (
    <Container>
      <HeaderWrapper>
        <a href={header.logo_link || '/'}>
          <img src={header.logo?.url ? `https://www.ford360guide.com${header.logo?.url}` : Logo} alt="" />
        </a>
      </HeaderWrapper>
    </Container>
  )
}

export default HeaderHome
