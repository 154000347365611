import React, { useEffect } from 'react'
import { Template } from '../../../components/template'

import HeaderHome from '../../../components/HeaderHome'
import Footer from '../../../components/Footer'
import CarCard from './CarCard'
import { navigate } from 'gatsby'

import axios from 'axios'
import slugify from 'slugify'
import { forwardParams } from '../../../helpers'

const VIN_ENDPOINT = `https://app.ford360guide.com`
// const VIN_ENDPOINT = `http://localhost:3001`

const LandingCars = ({ pageContext }) => {
  const {
    metatag_title,
    metatag_description,
    metatag_language,
    Privacy_policy_url,
    title,
    subtitle,
    has_cookie_consent,
    redirect_link_button_text,
    privacy_policy_text,
    privacy_policy_subtext,
    privacy_policy_accept_button_text
  } = pageContext.website.website_info
  const { button_text, disable_button_text, second_subtitle } = pageContext.website

  const { cars, models, envs } = pageContext

  useEffect(() => {
    const vin = window.location.search.split('=')[1]

    const getSeq = async () => {
      const country = envs.SEQS.split('_')[0]
      let isoCountry = ''

      if (country === 'br') {
        isoCountry = 'bra'
      } else if (country === 'ar') {
        isoCountry = 'arg'
      }

      const { data } = await axios.get(`${VIN_ENDPOINT}/vin?n=${vin}&country=${isoCountry}`)

      const matchingModel = models.find(model => {
        return model[envs.SEQS].find(seq => seq.code === data.seq)
      })

      if (!matchingModel) return

      const version = matchingModel[envs.VERSION]
      const car = cars.find(car => {
        return car[envs.VERSIONS].find(ver => ver.id === version.id)
      })

      if (!car) return

      const { Full_Name, Year_Model } = matchingModel

      const categorySlug = slugify(car[envs.CATEGORY].category_slug.toLowerCase())
      const carDetailsSlug = slugify(`${Year_Model} ${Full_Name.toLowerCase().replace(`${Year_Model}`, '')}`)

      navigate(forwardParams(`${categorySlug}/${carDetailsSlug}`, ['vin']))
    }

    if (vin) getSeq()
  }, [])

  return (
    <Template
      isHome
      title={metatag_title}
      description={metatag_description}
      language={metatag_language}
      has_cookie_consent={has_cookie_consent}
      site={pageContext.envs.SITE}
      privacy_policy_text={privacy_policy_text}
      privacy_policy_subtext={privacy_policy_subtext}
      privacy_policy_accept_button_text={privacy_policy_accept_button_text}
    >
      <HeaderHome header={pageContext.website.header} />
      <CarCard
        cars={cars}
        button_text={button_text}
        disable_button_text={disable_button_text}
        title={title}
        subtitle={subtitle}
        categoryTableName={pageContext.envs.CATEGORY}
        second_subtitle={second_subtitle}
        pageContext={pageContext}
        redirect_link_button_text={redirect_link_button_text}
      />
      <Footer privacyPolicyUrl={Privacy_policy_url} footer={pageContext.website.footer} header={pageContext.website.header} />
    </Template>
  )
}

export default LandingCars
