import { useCallback, useReducer, useEffect, useState } from 'react'
import slugify from 'slugify'

const isBrowser = typeof window !== 'undefined'

const ACTIONS = {
  SET_YEARS_OPTIONS: 'set_years_options',
  SET_SELECTED_YEAR: 'set_selected_year',
  SET_SELECTED_VERSION: 'set_selected_version',
  SET_SELECTED_MODEL: 'set_selected_model',
  SET_ONLY_YEAR: 'set_only_year'
}

const initialState = {
  showModelSelect: false,
  showContinueButton: false,
  yearOptions: [],
  versionOptions: [],
  modelOptions: [],
  selectedYear: null,
  selectedVersion: null,
  selectedModel: null
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_YEARS_OPTIONS:
      return { ...state, yearOptions: action.payload }
    case ACTIONS.SET_ONLY_YEAR:
      return {
        ...state,
        selectedYear: action.payload.selectedYear,
        versionOptions: action.payload.versionOptions,
        modelOptions: []
      }
    case ACTIONS.SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload.selectedYear,
        versionOptions: action.payload.versionOptions,
        modelOptions: [],
        selectedVersion: null,
        selectedModel: null,
        showModelSelect: false
      }
    case ACTIONS.SET_SELECTED_VERSION:
      return {
        ...state,
        selectedVersion: action.payload.selectedVersion,
        modelOptions: action.payload.modelOptions,
        showModelSelect: true,
        selectedModel: null,
        showContinueButton: false
      }
    case ACTIONS.SET_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.payload.selectedModel,
        showContinueButton: true
      }

    default:
      throw new Error('Unknown Action')
  }
}

const valueToOptionType = value => (value ? { value, label: value } : undefined)
const arrayToOptionTypes = arrayValue => (arrayValue ? arrayValue.map(value => valueToOptionType(value)) : [])

export const useSelectModel = ({ models, hasYearDropDown, envs, car }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getYearOptions = useCallback(
    () =>
      Array.from(new Set(models.map(model => model.Year_Model)))
        .sort()
        .reverse(),
    [models]
  )

  const getVersionOptions = useCallback(
    year => {
      const versions = models
        .filter(model => model.Year_Model === year && !!model[envs.VERSION].Version_Name)
        .map(model => model[envs.VERSION])

      const uniqueVersions = []

      for (const version of versions) {
        const exitingVersionInArray = uniqueVersions.find(_version => version.Version_Name === _version.Version_Name)

        if (!exitingVersionInArray) uniqueVersions.push(version)
      }

      uniqueVersions.sort((versionA, versionB) => {
        const priorityA = versionA.priority
        const priorityB = versionB.priority

        if (!priorityA || !priorityB) return -Infinity
        if (priorityA < priorityB) return -1
        if (priorityA > priorityB) return 1

        return 0
      })

      return uniqueVersions
    },
    // get unique version form models and then sort them by priority
    [models, envs.VERSION]
  )

  const getModelOptions = useCallback(
    (year, versionName) =>
      models
        .filter(model => model.Year_Model === year && model[envs.VERSION].Version_Name === versionName)
        .sort((modelA, modelB) => {
          const nameA = modelA.Model_Name.toUpperCase()
          const nameB = modelB.Model_Name.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        }),
    [models, envs.VERSION]
  )

  useEffect(() => {
    const yearOptions = getYearOptions()
    dispatch({ type: ACTIONS.SET_YEARS_OPTIONS, payload: yearOptions })
  }, [getYearOptions])

  const handleSelectYearOption = year => {
    const selectedYear = year
    const versionOptions = getVersionOptions(selectedYear)

    if (versionOptions.length === 1) {
      dispatch({ type: ACTIONS.SET_SELECTED_YEAR, payload: { selectedYear, versionOptions } })

      const modelOptions = getModelOptions(selectedYear, versionOptions[0].Version_Name)
      const selectedVersion = versionOptions.find(version => version.Version_Name === versionOptions[0].Version_Name)

      dispatch({ type: ACTIONS.SET_SELECTED_VERSION, payload: { selectedVersion, modelOptions } })

      if (modelOptions.length === 1) {
        dispatch({ type: ACTIONS.SET_SELECTED_MODEL, payload: { selectedModel: modelOptions[0] } })
      }
    } else {
      dispatch({ type: ACTIONS.SET_SELECTED_YEAR, payload: { selectedYear, versionOptions } })
    }
  }

  const handleSelectVersionOption = versionName => {
    const modelOptions = getModelOptions(state.selectedYear, versionName)
    const selectedVersion = state.versionOptions.find(version => version.Version_Name === versionName)

    dispatch({ type: ACTIONS.SET_SELECTED_VERSION, payload: { selectedVersion, modelOptions } })

    if (modelOptions.length === 1) {
      dispatch({ type: ACTIONS.SET_SELECTED_MODEL, payload: { selectedModel: modelOptions[0] } })
    }
  }

  const handleSelectModelOption = modelName => {
    const selectedModel = state.modelOptions.find(model => model.Model_Name === modelName)

    dispatch({ type: ACTIONS.SET_SELECTED_MODEL, payload: { selectedModel } })
  }

  // when page is loaded, load year options, select version if only one, select model if only one
  useEffect(() => {
    if (!state.yearOptions.length) return
    if (!(!hasYearDropDown || state.yearOptions.length === 1)) return

    const versionOptions = getVersionOptions(state.yearOptions[0])
    const selectedYear = state.yearOptions[0]
    dispatch({ type: ACTIONS.SET_SELECTED_YEAR, payload: { selectedYear, versionOptions } })

    if (versionOptions.length > 1) return
    const selectedVersion = versionOptions[0]
    const modelOptions = getModelOptions(selectedYear, selectedVersion.Version_Name)

    dispatch({ type: ACTIONS.SET_SELECTED_VERSION, payload: { selectedVersion, modelOptions } })

    if (modelOptions.length > 1) return
    const selectedModel = modelOptions[0]

    dispatch({ type: ACTIONS.SET_SELECTED_MODEL, payload: { selectedModel } })
  }, [hasYearDropDown, state.yearOptions, getModelOptions, getVersionOptions])

  const buildModelLink = useCallback(() => {
    if (!state.selectedModel) return '#'

    const { Full_Name, Year_Model } = state.selectedModel
    return `${slugify(car[envs.CATEGORY].category_slug.toLowerCase())}/${slugify(
      `${Year_Model} ${Full_Name.toLowerCase().replace(`${Year_Model}`, '')}`
    )}`
  }, [state.selectedModel])

  return {
    handleSelectYearOption,
    handleSelectVersionOption,
    handleSelectModelOption,
    selectedYear: valueToOptionType(state.selectedYear),
    selectedVersion: valueToOptionType(state.selectedVersion?.Version_Name),
    selectedModel: valueToOptionType(state.selectedModel?.Model_Name),
    yearOptions: arrayToOptionTypes(state.yearOptions),
    versionOptions: arrayToOptionTypes(state.versionOptions.map(v => v.Version_Name)),
    modelOptions: arrayToOptionTypes(state.modelOptions.map(m => m.Model_Name)),
    showModelSelect: state.showModelSelect,
    showContinueButton: state.showContinueButton,
    buildModelLink
  }
}
