import React from 'react'

import { useState } from 'react'
import Slider from '../../../../components/Slider'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { CarCardShow } from './car-card-show'

export const CarSlider = ({ cars, pageContext, disable_button_text, redirect_link_button_text }) => {
  const breakpoints = useBreakpoint()
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  return (
    <Slider activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex}>
      {cars.map((car, index) => {
        const carPicture = car?.car_picture?.url
        return (
          <div key={index} style={{ display: breakpoints.sm && 'flex', justifyContent: 'center' }}>
            <CarCardShow
              car={car}
              carPicture={carPicture}
              pageContext={pageContext}
              key={index}
              disable_button_text={disable_button_text}
              redirect_link_button_text={redirect_link_button_text}
            />
          </div>
        )
      })}
    </Slider>
  )
}
