import React, { useState, useRef, useEffect } from 'react'

import {
  Input,
  SelectWrapper,
  OptionsWrapper,
  Option,
  InputSpan,
  Label,
  SelectContainer,
  ErrorMessage,
  InputInnerWrapper
} from './styled'

const Select = ({
  placeholder,
  options,
  label,
  onChange,
  value,
  showNoFeeback,
  textError,
  onClick,
  showCustomError = false
}) => {
  const [showSelection, setShowSelection] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [selectIsFocused, setSelectIsFocused] = useState(false)
  const optionWrapperRef = useRef()

  const showOptions = () => {
    setShowSelection(state => !state)

    if (!value || !value.value) {
      setShowError(true)
    }
  }

  useEffect(() => {
    if (!value) {
      setShowSuccess()
    } else {
      setShowSuccess(true)
    }
  }, [value])

  const selectOption = value => {
    setShowSelection(false)
    setShowError(false)
    setShowSuccess(true)
    onChange({ label: value, value: value })
  }

  useEffect(() => {
    const handleMouseClick = document.addEventListener('click', event => {
      if (!(optionWrapperRef && optionWrapperRef.current && optionWrapperRef.current.contains(event.target))) {
        setShowSelection(false)
      }
    })

    return () => document.removeEventListener('click', handleMouseClick)
  }, [optionWrapperRef])

  return (
    <SelectContainer onClick={onClick} id="select-container">
      <Label>
        <span style={{ marginRight: '0.5rem' }}>{label}</span>
        {showError && !showNoFeeback && <span className="fds-icon fds-font--ford-icons__error"></span>}
        {showSuccess && !showNoFeeback && <span className="fds-icon fds-font--ford-icons__success"></span>}
      </Label>
      <SelectWrapper ref={optionWrapperRef}>
        <Input
          hasOptions={options.length}
          onClick={() => (options.length ? showOptions() : {})}
          error={showError && !showNoFeeback}
          success={showSuccess && !showNoFeeback}
          onFocus={() => setSelectIsFocused(true)}
          onBlur={() => setSelectIsFocused(false)}
          selectIsFocused={selectIsFocused && showSelection}
        >
          <InputInnerWrapper>
            <InputSpan
              style={
                !value || !value.value
                  ? {
                      color: '#4d4d4d',
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '180px'
                    }
                  : { display: 'inherit' }
              }
            >
              {value && value.value ? value.value : placeholder}
            </InputSpan>{' '}
            <span
              style={{ transform: showSelection ? 'rotate(180deg)' : 'rotate(0deg)', top: 17, fontSize: 15 }}
              className="fds-dropdown__icon"
            />
          </InputInnerWrapper>
        </Input>
        <OptionsWrapper display={showSelection}>
          {options.map(option => {
            return <Option onClick={() => selectOption(option.value)}>{option.value}</Option>
          })}
        </OptionsWrapper>
        {showError && !showNoFeeback && (
          <ErrorMessage>{textError ? `${textError}` : `Por favor, selecione um ${label}`}</ErrorMessage>
        )}
        {showCustomError && <ErrorMessage>{textError}</ErrorMessage>}
      </SelectWrapper>
    </SelectContainer>
  )
}

export default Select
