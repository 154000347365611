import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 100px;
  /* margin-top: -8px; */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  @media only screen and (max-width: 600px) {
    height: 70px;
  }

  img{
    width: 102px;

    @media only screen and (min-width:1024px){
      width: 95px;
    }
  }
  
  @media only screen and (min-width:1024px){
    justify-content: flex-start;
  }
`;
